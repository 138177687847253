<template>
  <component
    :is="isActionTrigger(link) ? 'button' : 'a'"
    :open-new-tab="openNewTab"
    :class="[
      'text-center rounded-md shadow-md hover:shadow-lg transition ease-in-out',
      { 'opacity-50 cursor-not-allowed': disabled },
      twMerge(
        color === 'primary' &&
          variant === 'contained' &&
          'bg-primary text-white hover:bg-teal-900 hover:active:shadow-none',
        variant === 'contained' && 'px-4 py-2 rounded-lg',
        color === 'primary' &&
          variant === 'outlined' &&
          'border border-primary text-primary py-2',
        variant === 'text' &&
          ' underline font-semibold shadow-none hover:shadow-none hover:text-primary',
        color === 'secondary' &&
          variant === 'contained' &&
          'bg-primary-light text-primary hover:bg-primary hover:text-white',
        color === 'tertiary' &&
          variant === 'contained' &&
          'bg-gray-50 text-gray-800 hover:bg-gray-100',
        $attrs.class
      ),
    ]"
    :disabled="disabled"
    :href="link"
  >
    <span v-if="showSpinnerLeft" class="loader"></span>
    <!--@slot Use this slot to place content inside the button.-->
    <slot />
  </component>
</template>
<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const props = withDefaults(
  defineProps<{
    color?: string
    variant?: string
    disabled?: boolean
    link?: string
    openNewTab?: boolean
    showSpinnerLeft?: boolean
  }>(),
  {
    color: 'primary',
    variant: 'contained',
    disabled: false,
    link: '',
    openNewTab: false,
    showSpinnerLeft: false,
  }
)
const isActionTrigger = (uri) => !(uri?.includes('/') || uri?.includes(':'))
</script>
<style scoped>
.loader {
  width: 24px;
  height: 24px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
